<template>
    <div class="value">
        <div class="value__minus value__button" @click="valueMinus">-</div>
        <div class="value__value"> {{ value }} </div>
        <div class="value__plus value__button" @click="valuePlus">+</div>
    </div>
    
</template>

<script>

    export default {
        name: 'BaseCounter',
        props: {
            maxValue: {
                type: Number,
                default: 1
            },
            value: {
                type: Number,
                default: 1
            }
        },
        methods: {
            valueMinus() {
                if (this.value > 1) {
                    this.$emit('change', this.value - 1);
                }
            },
            valuePlus() {
                if (this.value < this.maxValue) {
                    this.$emit('change', this.value + 1);
                }
            }
        }
    }
</script>

<style lang="scss"> 
    .value {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        font-style: normal;
        margin-left: 20px;
        &__value {
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.02em;
            color: #000000;
            margin-top: 1px;
        }
        &__button {
            font-size: 20px;
            letter-spacing: -0.02em;
            color: #90949A;
            cursor: pointer;
        }
        &__plus {
            margin-left: 10px;
            padding-bottom: 2px;
        }
        &__minus {
            margin-right: 10px;
        }
    }
</style>