<template>
    <div class="trip-booking" v-if="translates && translates[langUrl]">
        <div class="trip-booking__title">{{ translates[langUrl].pageTitle[lang] }}</div>
        <div style="display: flex; flex-direction: row">
            <div class="trip-booking__main-container">
                <div class="trip-booking__main-container__dates">
                    <div class="trip-booking__main-container__dates__info">
                        <div class="trip-booking__main-container__dates__info__date">
                            <div class="trip-booking__block-title">1. {{ translates[langUrl].mainBlock_title[lang] }}</div>
                            <div class="trip-booking__main-container__dates__info__date__value">{{ dateFormated(activeArrival.start) }} -<br />{{ dateFormated(activeArrival.finish) }}</div>
                            <DateSelect 
                                class="no-text"
                                :items="dateSelectItems"
                                item-text="label"
                                item-value="value"
                                :value="activeArrivalIndex"
                                @change="setActiveArrival"
                            />
                        </div>
                        <div class="trip-booking__main-container__dates__info__rules">
                            <div class="trip-booking__main-container__dates__info__rules__item">
                                <div 
                                    class="trip-booking__main-container__dates__info__rules__item__picture users"
                                ></div>
                                <div class="trip-booking__main-container__dates__info__rules__item__text">
                                    <div class="rules__item__name"> {{ emptyPlaces }} {{ translates[langUrl].mainBlock_title_part_1[lang] }} {{ activeArrival.places }} {{ translates[langUrl].mainBlock_title_part_2[lang] }}</div>
                                    <div>{{ translates[langUrl].mainBlock_places_comment[lang] }}</div>
                                </div>
                            </div>
                            <div class="trip-booking__main-container__dates__info__rules__item">
                                <div 
                                    class="trip-booking__main-container__dates__info__rules__item__picture coin"
                                ></div>
                                <div class="trip-booking__main-container__dates__info__rules__item__text">
                                    <div class="rules__item__name">{{ translates[langUrl].mainBlock_deposit_title[lang] }}</div>
                                    <div>{{ translates[langUrl].mainBlock_deposit_comment_part_1[lang] }} {{ booking.depositPercent }}{{ translates[langUrl].mainBlock_deposit_comment_part_2[lang] }} (<span v-html="priceFormated(booking.deposit, booking.currency)"></span>) {{ translates[langUrl].mainBlock_deposit_comment_part_3[lang] }} </div>
                                </div>
                            </div>
                            <div class="trip-booking__main-container__dates__info__rules__item">
                                <div 
                                    class="trip-booking__main-container__dates__info__rules__item__picture shieldCheck"
                                ></div>
                                <div class="trip-booking__main-container__dates__info__rules__item__text">
                                    <div class="rules__item__name">{{ translates[langUrl].mainBlock_booking_title[lang] }}</div>
                                    <div v-html="translates[langUrl].mainBlock_booking_comment[lang]"></div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!isDesktop"
                    class="trip-booking__main-container__my-trip"
                >
                    <div class="trip-booking__block-title">{{ translates[langUrl].sideBlock_title[lang] }}</div>
                    <h1 class="trip-booking__main-container__my-trip__title">{{ trip.name }}</h1>
                    <div class="trip-booking__main-container__my-trip__rating"> 
                        <template v-if="trip.programs">{{ translates[langUrl].sideBlock_duration[lang] }} {{ trip.programs.length }} {{ lastDigitToWord(trip.programs.length) }}</template> 
                    </div>
                    <div class="trip-booking__main-container__my-trip__dates">
                        <div style="margin-bottom: 10px;"> <span style="font-weight: 600;">{{ translates[langUrl].sideBlock_start[lang] }}</span> {{ dateFormated(activeArrival.start) }}</div>
                        <div> <span style="font-weight: 600;">{{ translates[langUrl].sideBlock_finish[lang] }}</span> {{ dateFormated(activeArrival.finish) }} </div>
                    </div>
                    <div class="trip-booking__main-container__my-trip__info trip-booking__main-container__my-trip__info__value travelers">
                        {{ translates[langUrl].sideBlock_travelers[lang] }} <div> 
                            <BaseCounter 
                                :value="booking.places"
                                :max-value="activeArrival.empty"
                                @change="updateTraveler"
                            />
                            <!-- {{ booking.places }}  -->
                        </div>
                    </div>
                    <div class="trip-booking__main-container__my-trip__price">
                        <div class="">
                            <div class="trip-booking__main-container__my-trip__info">
                                <div class="trip-booking__main-container__my-trip__info__value">
                                    <div style="max-width: 220px">{{ translates[langUrl].sideBlock_price[lang] }}</div>
                                    <div style="text-align: right;">
                                        <span v-html="priceFormated(cost(), activeArrival.currency)"></span><br>
                                        <span class="trip-booking__main-container__my-trip__info__comment">
                                            <span v-html="priceFormated(price(), activeArrival.currency)"></span> x {{ booking.places }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="trip-booking__main-container__my-trip__info trip-booking__main-container__my-trip__info__value">Комиссия сервиса <div>₽0</div></div> -->
                    </div>
                    <div class="trip-booking__main-container__my-trip__info trip-booking__main-container__my-trip__info__value">
                        {{ translates[langUrl].sideBlock_finalPrice[lang] }} <div v-html="priceFormated(cost(), activeArrival.currency)"></div>
                    </div>
                    <div class="trip-booking__main-container__my-trip__info prepay">
                        <div class="trip-booking__main-container__my-trip__info__comment">{{ translates[langUrl].sideBlock_prepay_comment[lang] }}</div>
                        <div class="trip-booking__main-container__my-trip__info__value final-price">{{ translates[langUrl].sideBlock_prepay_titile[lang] }}<div v-html="priceFormated(booking.deposit, activeArrival.currency)"></div></div>
                    </div>
                    <BaseButton
                        class="button-fill"
                        style="width: 100%"
                        @click="pay()">{{ translates[langUrl].sideBlock_button_pay[lang] }}</BaseButton>
                    <div class="trip-booking__main-container__my-trip__info__personal-info">
                        <BaseCheckbox 
                            v-model="personalDataAgree"
                        />
                        <div class="trip-booking__main-container__my-trip__info__personal-info__text">
                            {{ translates[langUrl].personalInfoPopup_part_1[lang] }}
                            <a class="trip-booking__main-container__my-trip__info__personal-info__text__accent"
                                :href="privacy" target="_blank"
                            >
                                {{ translates[langUrl].sideBlock_personalInfo_part_2[lang] }}
                            </a>
                            {{ translates[langUrl].sideBlock_personalInfo_part_3[lang] }} 
                            <a class="trip-booking__main-container__my-trip__info__personal-info__text__accent"
                                :href="bookingTerms" target="_blank"
                            >
                                {{ translates[langUrl].sideBlock_personalInfo_part_4[lang] }}
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    ref="bookingSidebarStop"
                    class="trip-booking__main-container__details">
                    <div class="trip-booking__block-title">2. {{ translates[langUrl].detailsBlock_title[lang] }}</div>
                    <div class="trip-booking__main-container__details__counter">
                        <div>{{ translates[langUrl].detailsBlock_travelers[lang] }}</div>
                        <BaseCounter
                            :value="booking.places"
                            :max-value="activeArrival.empty"
                            @change="updateTraveler"
                        />
                    </div>
                    <div class="trip-booking__main-container__details__traveler__title-container">
                        <div class="trip-booking__main-container__details__traveler__title-container__text">
                            <div class="trip-booking__main-container__details__traveler__title">{{ translates[langUrl].detailsBlock_mainTraveler[lang] }}</div>
                            <div class="trip-booking__main-container__details__traveler__title-comment">{{ translates[langUrl].detailsBlock_contacts[lang] }}</div>
                        </div>
                        <div class="trip-booking__main-container__details__traveler__title-container__switcher">
                            <div class="trip-booking__main-container__details__traveler__title-container__switcher__title">{{ translates[langUrl].detailsBlock_mainTraveler_switcher[lang] }}</div>
                            <v-switch style="margin-top: 5px"
                                :input-value="defaultUser"
                                @change="showDefaultUser"
                                hide-details
                            />
                        </div>
                        
                    </div>
                    <ValidationObserver ref="validator" slim>
                        <div class="trip-booking__main-container__details__traveler__inputs border">
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="trip-booking__main-container__details__traveler__input">
                                    <div class="trip-booking__main-container__details__traveler__input__text">
                                        <div class="trip-booking__main-container__details__traveler__input__name">{{ translates[langUrl].detailsBlock_firstname[lang] }}</div>
                                        <div class="trip-booking__main-container__details__traveler__input__comment">{{ translates[langUrl].detailsBlock_contacts_comment[lang] }}</div>
                                    </div>
                                    <BaseTextField
                                        v-model="booking.mainTraveler.firstname"
                                        :label="translates[langUrl].detailsBlock_firstname[lang] + ' ' + translates[langUrl].detailsBlock_contacts_comment[lang]"
                                        style="max-width: 386px"
                                        :error-messages="errors[0]"
                                    />
                                </div>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="trip-booking__main-container__details__traveler__input">
                                    <div class="trip-booking__main-container__details__traveler__input__text">
                                        <div class="trip-booking__main-container__details__traveler__input__name">{{ translates[langUrl].detailsBlock_lastname[lang] }}</div>
                                        <div class="trip-booking__main-container__details__traveler__input__comment">{{ translates[langUrl].detailsBlock_contacts_comment[lang] }}</div>
                                    </div>
                                    <BaseTextField 
                                        v-model="booking.mainTraveler.lastname"
                                        :label="translates[langUrl].detailsBlock_lastname[lang] + ' ' + translates[langUrl].detailsBlock_contacts_comment[lang]"
                                        style="max-width: 386px"
                                        :error-messages="errors[0]"
                                    />
                                </div>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="trip-booking__main-container__details__traveler__input">
                                    <div class="trip-booking__main-container__details__traveler__input__text">
                                        <div class="trip-booking__main-container__details__traveler__input__name">{{ translates[langUrl].detailsBlock_email[lang] }}</div>
                                    </div>
                                    <BaseTextField 
                                        v-model="booking.mainTraveler.mail"
                                        :label="translates[langUrl].detailsBlock_email[lang]"
                                        style="max-width: 386px"
                                        :error-messages="errors[0]"
                                    />
                                </div>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="trip-booking__main-container__details__traveler__input">
                                    <div class="trip-booking__main-container__details__traveler__input__text">
                                        <div class="trip-booking__main-container__details__traveler__input__name">{{ translates[langUrl].detailsBlock_phone[lang] }}</div>
                                    </div>
                                    <BasePhoneNumberInput
                                        v-model="booking.mainTraveler.phone"
                                        @update="number($event)"
                                        :error-messages="errors[0]"
                                        style="max-width: 385px"
                                    />
                                </div>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="trip-booking__main-container__details__traveler__input">
                                    <div class="trip-booking__main-container__details__traveler__input__text">
                                        <div class="trip-booking__main-container__details__traveler__input__name">{{ translates[langUrl].detailsBlock_birthday[lang] }}</div>
                                    </div>
                                    <div style="width: 386px">
                                        <BaseDatePicker
                                            :value="booking.mainTraveler.birthday"
                                            class="no-label"
                                            :label="translates[langUrl].detailsBlock_birthday[lang]"
                                            :error-messages="errors[0]"
                                            @change="updateBirthday"
                                        />
                                    </div>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <div class="trip-booking__main-container__details__traveler__input">
                                    <div class="trip-booking__main-container__details__traveler__input__text">
                                        <div class="trip-booking__main-container__details__traveler__input__name">{{ translates[langUrl].detailsBlock_gender[lang] }}</div>
                                    </div>
                                    <v-radio-group
                                        v-model="booking.mainTraveler.gender"
                                        :error-messages="errors[0]"
                                    >
                                        <BaseRadio 
                                            :label="translates[langUrl].detailsBlock_gender_female[lang]"
                                            value="Женский"
                                            style="margin-right: 55px"
                                        />
                                        <BaseRadio 
                                            :label="translates[langUrl].detailsBlock_gender_male[lang]"
                                            value="Мужской"
                                        />
                                        <BaseRadio
                                            :label="translates[langUrl].detailsBlock_gender_other[lang]"
                                            value="other"
                                        />
                                    </v-radio-group>
                                </div>
                            </ValidationProvider>
                        </div>
                        <template v-for="(traveler, index) in booking.additionalTravelers">
                            <div
                                :key="'traveler' + index"
                                class="trip-booking__main-container__details__traveler">
                                <div class="trip-booking__main-container__details__traveler__title-container">
                                    <div class="trip-booking__main-container__details__traveler__title">{{ translates[langUrl].detailsBlock_additionalTraveler[lang] }}{{ index + 2 }}</div>
                                    <div class="trip-booking__main-container__details__traveler__title-comment">{{ translates[langUrl].detailsBlock_additionalTraveler_contacts[lang] }}</div>
                                </div>
                                <div class="trip-booking__main-container__details__traveler__inputs">
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="trip-booking__main-container__details__traveler__input">
                                            <div class="trip-booking__main-container__details__traveler__input__text">
                                                <div class="trip-booking__main-container__details__traveler__input__name">{{ translates[langUrl].detailsBlock_firstname[lang] }}</div>
                                                <div class="trip-booking__main-container__details__traveler__input__comment">{{ translates[langUrl].detailsBlock_contacts_comment[lang] }}</div>
                                            </div>
                                            <BaseTextField 
                                                v-model="traveler.firstname"
                                                :label="translates[langUrl].detailsBlock_firstname[lang] + ' ' + translates[langUrl].detailsBlock_contacts_comment[lang]"
                                                style="max-width: 386px"
                                                :error-messages="errors[0]"
                                            />
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="trip-booking__main-container__details__traveler__input">
                                            <div class="trip-booking__main-container__details__traveler__input__text">
                                                <div class="trip-booking__main-container__details__traveler__input__name">{{ translates[langUrl].detailsBlock_lastname[lang] }}</div>
                                                <div class="trip-booking__main-container__details__traveler__input__comment">{{ translates[langUrl].detailsBlock_contacts_comment[lang] }}</div>
                                            </div>
                                            <BaseTextField 
                                                v-model="traveler.lastname"
                                                :label="translates[langUrl].detailsBlock_lastname[lang] + ' ' + translates[langUrl].detailsBlock_contacts_comment[lang]"
                                                style="max-width: 386px"
                                                :error-messages="errors[0]"
                                            />
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="trip-booking__main-container__details__traveler__input">
                                            <div class="trip-booking__main-container__details__traveler__input__text">
                                                <div class="trip-booking__main-container__details__traveler__input__name">{{ translates[langUrl].detailsBlock_birthday[lang] }}</div>
                                            </div>
                                            <div style="width: 386px">
                                                <BaseDatePicker
                                                    :value="traveler.birthday"
                                                    style="max-width: 386px; flex-direction: column"
                                                    @change="updateAdditionalBirthday(index, $event)"
                                                    :error-messages="errors[0]"
                                                />
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <div class="trip-booking__main-container__details__traveler__input">
                                            <div class="trip-booking__main-container__details__traveler__input__text">
                                                <div class="trip-booking__main-container__details__traveler__input__name">{{ translates[langUrl].detailsBlock_gender[lang] }}</div>
                                            </div>
                                            <v-radio-group
                                                v-model="traveler.gender"
                                                :error-messages="errors[0]"
                                            >
                                                <BaseRadio
                                                    :label="translates[langUrl].detailsBlock_gender_female[lang]"
                                                    style="margin-right: 55px"
                                                    value="female"
                                                />
                                                <BaseRadio
                                                    :label="translates[langUrl].detailsBlock_gender_male[lang]"
                                                    value="male"
                                                />
                                                <BaseRadio
                                                    :label="translates[langUrl].detailsBlock_gender_other[lang]"
                                                    value="other"
                                                />
                                            </v-radio-group>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </template>
                    </ValidationObserver>
                    <BaseButton
                        class="button-fill"
                        style="width: 100%"
                        @click="pay()">{{ translates[langUrl].sideBlock_button_pay[lang] }}</BaseButton>
                    <div class="trip-booking__main-container__my-trip__info__personal-info">
                        <BaseCheckbox 
                            v-model="personalDataAgree"
                        />
                        <div class="trip-booking__main-container__my-trip__info__personal-info__text">
                            {{ translates[langUrl].sideBlock_personalInfo_part_1[lang] }}
                            <a class="trip-booking__main-container__my-trip__info__personal-info__text__accent"
                                :href="privacy" target="_blank"
                            >
                                {{ translates[langUrl].sideBlock_personalInfo_part_2[lang] }}
                            </a>
                            {{ translates[langUrl].sideBlock_personalInfo_part_3[lang] }} 
                            <a class="trip-booking__main-container__my-trip__info__personal-info__text__accent"
                                :href="bookingTerms" target="_blank"
                            >
                                {{ translates[langUrl].sideBlock_personalInfo_part_4[lang] }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="isDesktop"
                ref="bookingSidebarContainer"
                style="position: relative; width: 30%">
                <div
                    ref="bookingSidebar"
                    class="trip-booking__main-container__my-trip desktop"
                    :class="{ 'trip-booking__main-container__my-trip-fixed': bookingSidebarFixed, 'trip-booking__main-container__my-trip-absolute': bookingSidebarAbsolute }"
                >
                    <div class="trip-booking__block-title">{{ translates[langUrl].sideBlock_title[lang] }}</div>
                    <h1 class="trip-booking__main-container__my-trip__title">{{ trip.name }}</h1>
                    <div class="trip-booking__main-container__my-trip__rating"> 
                        <template v-if="trip.programs">{{ translates[langUrl].sideBlock_duration[lang] }} {{ trip.programs.length }} {{ lastDigitToWord(trip.programs.length) }}</template> 
                    </div>
                    <div class="trip-booking__main-container__my-trip__dates">
                        <div style="margin-bottom: 10px;"> <span style="font-weight: 600;">{{ translates[langUrl].sideBlock_start[lang] }}</span> {{ dateFormated(activeArrival.start) }}</div>
                        <div> <span style="font-weight: 600;">{{ translates[langUrl].sideBlock_finish[lang] }}</span> {{ dateFormated(activeArrival.finish) }} </div>
                    </div>
                    <div class="trip-booking__main-container__my-trip__info trip-booking__main-container__my-trip__info__value travelers">
                        {{ translates[langUrl].sideBlock_travelers[lang] }}<div> 
                            <BaseCounter
                                :value="booking.places"
                                :max-value="activeArrival.empty"
                                @change="updateTraveler"
                            />
                        </div>
                    </div>
                    <div class="trip-booking__main-container__my-trip__price">
                        <div class="">
                            <div class="trip-booking__main-container__my-trip__info">
                                <div class="trip-booking__main-container__my-trip__info__value">
                                    <div style="max-width: 220px">{{ translates[langUrl].sideBlock_price[lang] }}</div>
                                    <div style="text-align: right;">
                                        <span v-html="priceFormated(cost(), activeArrival.currency)"></span><br>
                                        <span class="trip-booking__main-container__my-trip__info__comment">
                                            <span v-html="priceFormated(price(), activeArrival.currency)"></span> x {{ booking.places }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="trip-booking__main-container__my-trip__info trip-booking__main-container__my-trip__info__value">Комиссия сервиса <div>₽0</div></div> -->
                    </div>
                    <div class="trip-booking__main-container__my-trip__info trip-booking__main-container__my-trip__info__value">
                        {{ translates[langUrl].sideBlock_finalPrice[lang] }} <div v-html="priceFormated(cost(), activeArrival.currency)"></div>
                    </div>
                    <div class="trip-booking__main-container__my-trip__info prepay">
                        <div class="trip-booking__main-container__my-trip__info__comment">{{ translates[langUrl].sideBlock_prepay_comment[lang] }}</div>
                        <div class="trip-booking__main-container__my-trip__info__value final-price">
                           {{ translates[langUrl].sideBlock_prepay_titile[lang] }} <div v-html="priceFormated(needToPay(), activeArrival.currency)"></div>
                        </div>
                    </div>
                    <BaseButton
                        class="button-fill"
                        style="width: 100%"
                        :disabled="!needToPay()"
                        @click="pay()">{{ translates[langUrl].sideBlock_button_pay[lang] }}</BaseButton>
                    <div class="trip-booking__main-container__my-trip__info__personal-info">
                        <BaseCheckbox 
                            v-model="personalDataAgree"
                        />
                        <div class="trip-booking__main-container__my-trip__info__personal-info__text">
                            {{ translates[langUrl].sideBlock_personalInfo_part_1[lang] }}
                            <a class="trip-booking__main-container__my-trip__info__personal-info__text__accent"
                                :href="privacy" target="_blank"
                            >
                                {{ translates[langUrl].sideBlock_personalInfo_part_2[lang] }}
                            </a>
                            {{ translates[langUrl].sideBlock_personalInfo_part_3[lang] }} 
                            <a class="trip-booking__main-container__my-trip__info__personal-info__text__accent"
                            :href="bookingTerms" target="_blank"
                            >
                                {{ translates[langUrl].sideBlock_personalInfo_part_4[lang] }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog
            v-model="personalDataPopup"
            max-width="500px"
        >
            <div class="trip-booking__personal-data">
                <div class="trip-booking__personal-data__title">{{ translates[langUrl].personalInfoPopup_part_title[lang] }}</div>
                <div class="trip-booking__personal-data__close" @click="closePersonalDataPopup"></div>
                <div class="trip-booking__personal-data__text">
                    {{ translates[langUrl].personalInfoPopup_part_1[lang] }}
                    <a class="trip-booking__main-container__my-trip__info__personal-info__text__accent"
                        :href="privacy" target="_blank"
                    >
                        {{ translates[langUrl].sideBlock_personalInfo_part_2[lang] }}
                    </a>
                    {{ translates[langUrl].sideBlock_personalInfo_part_3[lang] }} 
                    <a class="trip-booking__main-container__my-trip__info__personal-info__text__accent"
                        :href="bookingTerms" target="_blank"
                    >
                        {{ translates[langUrl].sideBlock_personalInfo_part_4[lang] }}
                    </a>
                </div>
                <BaseButton class="button-fill" style="width: 50%" @click="agreePersonalData">{{ translates[langUrl].personalInfoPopup_button[lang] }}</BaseButton>
            </div>
        </v-dialog>

        <StripePaymentPopup 
            v-model="stripePaymentPopup"
            :clientSecret="payment.clientSecret"
            :info="paymentInfo"
            width="600px" />
    </div>
</template>

<script>
    import DateSelect from '../../components/common/trip/DateSelect.vue';
    import BaseButton from '../../components/common/BaseButton.vue';
    import BaseCheckbox from '../../components/common/BaseCheckbox.vue';
    import BaseCounter from '../../components/common/BaseCounter.vue';
    import BaseTextField from '../../components/common/BaseTextField.vue';
    import BaseDatePicker from '../../components/common/BaseDatePicker.vue';
    import BaseRadio from '../../components/common/BaseRadio.vue';
    import StripePaymentPopup from '../../components/common/StripePaymentPopup.vue';
    import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
    import BasePhoneNumberInput from '../../components/common/BasePhoneInput.vue';

    import { imageSrc, isMobile, lastDigitToWord, priceFormated } from '@/helpers';
    import { mapState } from 'vuex';
    import store from '@/store';
    import { BOOKING_STATUS, BOOKING_TYPE, PAYMENT_STATUS } from '@/vars';
    import moment from '@/plugins/moment';

    export default {
        name: 'TripBooking',
        metaInfo () {
            return {
                title: 'Бронирование | ' + this.trip.name
            }
        },
        components: {
            DateSelect,
            BaseButton,
            BaseCounter,
            BaseTextField,
            BaseDatePicker,
            BaseRadio,
            BaseCheckbox,
            StripePaymentPopup,
            ValidationProvider,
            ValidationObserver,
            BasePhoneNumberInput
        },
        props: {

        },
        data: () => ({
            lastDigitToWord,
            priceFormated,
            BOOKING_STATUS,
            PAYMENT_STATUS,
            activeArrivalIndex: 0,
            bookingSidebarFixed: false,
            bookingSidebarAbsolute: false,
            personalDataAgree: false,
            personalDataPopup: false,
            isDesktop: false,
            date: [1, 31],
            phoneWithCode: '',
            defaultUser: false,
            stripePaymentPopup: false,
            clientSecret: '',
            langUrl: '/trip/code/booking/id'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('trips', {
                trip: state => state.entity
            }),
            ...mapState('bookings', {
                bookings: state => {
                    return state.entities.filter(item => { return item.status !== BOOKING_STATUS.CANCELED })
                },
                booking: state => {
                    return state.entity
                }
            }),
            ...mapState('payments', {
                payment: state => state.entity
            }),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            options () {
                return {
                    offset: 100
                }
            },
            privacy() {
                return `${ process.env.VUE_APP_CURRENT_URL }/privacy-policy`;
            },
            bookingTerms() {
                return `${ process.env.VUE_APP_CURRENT_URL }/booking-terms`;
            },
            paymentInfo() {
                return { 
                    start: this.booking.start,
                    finish: this.booking.finish,
                    name: this.trip.name,
                    places: this.booking.places,
                    amount: this.needToPay(),
                    currency: this.booking.currency,
                };
            },
            filteredArrivals() {
                const now = new Date();

                let arrivals = [];
                if(this.trip.bloggerTrips && this.trip.bloggerTrips.length) {
                    arrivals = this.trip.bloggerTrips.map(item => ({
                        _id: item._id,
                        type: BOOKING_TYPE.BLOGGER_TRIP,
                        price: item.price + item.bloggerAward,
                        currency: process.env.VUE_APP_DEFAULT_CURRENCY,
                        places: item.bloggerPlaces,
                        empty: item.empty || item.bloggerPlaces,
                        start: item.start,
                        finish: item.finish,
                    }));
                } else if(this.trip.arrivals) {
                    arrivals = this.trip.arrivals.map(item => ({
                        _id: item._id,
                        type: BOOKING_TYPE.ARRIVAL,
                        price: item.price,
                        currency: item.currency,
                        places: item.places,
                        empty: item.empty || item.places,
                        start: item.start,
                        finish: item.finish,
                        discount: item.discount,
                        discountBefore: item.discountBefore
                    }));
                }

                if(arrivals) {
                    arrivals = arrivals.filter(arrival => {
                        return new Date(arrival.start).getTime() > now.getTime();
                    });
                    arrivals = arrivals.sort((arrivalA, arrivalB) => {
                        return new Date(arrivalA.start).getTime() - new Date(arrivalB.start).getTime();
                    });
                    if(this.selectedMonth) {
                        arrivals = arrivals.filter(arrival => {
                            return moment(arrival.start).format('YYYY-MM-01') === this.selectedMonth;
                        });
                    } else {
                        arrivals = arrivals.slice(0, 4);
                    }
                }
                return arrivals || [];
            },
            dateSelectItems() {
                return this.filteredArrivals.map((item, index) => ({
                    label: moment(item.start).format('DD.MM.YY') + ' - ' + moment(item.finish).format('DD.MM.YY'),
                    value: index
                }));
            },
            activeArrival() {
                return this.filteredArrivals[this.activeArrivalIndex] || {};
            },
            isDiscountActive() {
                return moment(this.activeArrival.discountBefore).isAfter(moment().format())
            },
            emptyPlaces() {
                return this.activeArrival.empty - this.booking.places;
            },
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
            window.removeEventListener('scroll', this.handleScroll);
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            if (this.$route.params.trip_code) {
                // await store.dispatch('trips/get', { id: this.$route.params.id });
                // await store.dispatch('reviews/fetch', { filter: { trip: this.$route.params.id } });
                await store.dispatch('trips/getByCode', { code: this.$route.params.trip_code });
                await store.dispatch('bookings/get', { id: this.$route.params.booking_id });
                await store.dispatch('reviews/fetch', { filter: { trip: this.trip._id } });
                for(const i in this.filteredArrivals) {
                    if(this.booking.arrival.toString() === this.filteredArrivals[i]._id.toString()) {
                        this.activeArrivalIndex = +i;
                    }
                }
                const additionalTravelersCount = (this.booking.places - this.booking.additionalTravelers.length - 1);
                for(let i = 0; i < additionalTravelersCount; i++) {
                    this.booking.additionalTravelers.push({
                        birthday: null
                    });
                }
            }
            this.$root.$emit('preloaderHide');
        },
        methods: {
            showDefaultUser(value) {
                this.defaultUser = value
                if (this.defaultUser === true) {
                    store.commit('bookings/SET_ENTITY', {
                        ...this.booking,
                        mainTraveler: {
                            firstname: this.user.firstname,
                            lastname: this.user.lastname,
                            mail: this.user.email,
                            phone: this.user.phone,
                            birthday: this.user.birthday,
                        }
                    });
                    console.log(this.booking.mainTraveler);
                } else {
                    store.commit('bookings/SET_ENTITY', {
                        ...this.booking,
                        mainTraveler: {
                            firstname: '',
                            lastname: '',
                        }
                    });
                    console.log(this.booking.mainTraveler);
                }
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async setActiveArrival(index) {
                this.activeArrivalIndex = index;
                this.changeData()
            },
            async changeData()  {
                await store.commit('bookings/SET_ENTITY', {
                    trip: this.trip._id,
                    arrival: this.activeArrival._id,
                    start: this.activeArrival.start,
                    finish: this.activeArrival.finish,
                    price: this.price(),
                    discount: this.activeArrival.discount,
                    currency: this.activeArrival.currency,
                });
                await store.dispatch('bookings/save');
                await store.dispatch('bookings/get', { id: this.$route.params.booking_id });
            },
            handleScroll() {
                if(this.isDesktop) {
                    this.bookingSidebarAbsolute = (this.$refs.bookingSidebarStop.getBoundingClientRect().top + this.$refs.bookingSidebarStop.clientHeight) <= (this.$refs.bookingSidebar.clientHeight);
                    this.bookingSidebarFixed = !this.bookingSidebarAbsolute && (this.$refs.bookingSidebarContainer.getBoundingClientRect().top <= 10);
                }
            },
            dateFormated(item) {
                return moment(item).format('L') + ' (' + moment(item).format('dddd') + ')';
            },
            price() {
                if(this.activeArrival && this.activeArrival.discount && this.isDiscountActive) {
                    return Math.round(this.activeArrival.price * ((100 - this.activeArrival.discount) / 100))
                }
                return this.activeArrival.price;
            },
            cost() {
                return (this.price() * this.booking.places)
            },
            alreadyPaid() {
                if(this.booking.payments && this.booking.payments.length) {
                    return this.booking.payments.reduce((sum, payment) => {
                        return sum + (payment.status === PAYMENT_STATUS.SUCCESS ? payment.amount : 0);
                    }, 0);
                }
                return 0;
            },
            needToPay() {
                if(this.booking.payments && this.booking.payments.length) {
                    const paid = this.booking.payments.reduce((sum, payment) => {
                        return sum + (payment.status === PAYMENT_STATUS.SUCCESS ? payment.amount : 0);
                    }, 0);
                    return this.cost() - paid;
                }
                return this.booking.deposit * this.booking.places;
            },
            updateBirthday(value) {
                this.booking.mainTraveler.birthday = value;
            },
            updateAdditionalBirthday(index, value) {
                this.booking.additionalTravelers[index].birthday = value
            },
            updateTraveler(value) {
                if (this.booking.places < value) {
                    this.booking.additionalTravelers.push({
                        birthday: null
                    })
                } else if(this.booking.places > value) {
                    this.booking.additionalTravelers.splice((value - 1))
                }
                this.booking.places = value;
            },
            closePersonalDataPopup(){
                this.personalDataPopup = false;
            },
            async agreePersonalData() {
                this.personalDataAgree = true;
                this.personalDataPopup = false;
                await this.pay();
            },
            async pay() {
                const this_ = this;
                const valid = await this.$refs.validator.validate();
                if (valid) {
                    if (this.personalDataAgree){
                        this.booking.mainTraveler.phone = this.phoneWithCode
                        store.commit('bookings/SET_ENTITY', {
                            ...this.booking,
                            ...{ status: BOOKING_STATUS.FILLED }
                        });
                        await store.dispatch('bookings/save');

                        await store.commit('payments/SET_ENTITY', {
                            trip: this.trip._id,
                            booking: this.booking._id,
                            amount: +this.needToPay()
                        });
                        await store.dispatch('payments/save');
                        
                        if (process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) {
                            this.stripePaymentPopup = true;
                        } else {
                            const widget = new cp.CloudPayments();
                            widget.pay('auth', // или 'charge'
                                { //options
                                    publicId: process.env.VUE_APP_CLOUDPAYMENTS_PUBLIC_ID,
                                    description: 'Оплата бронирования',
                                    amount: +this.needToPay(),
                                    currency: this.booking.currency,
                                    invoiceId: this.booking._id,
                                    skin: "mini",
                                    data: {
                                        trip: this.trip._id,
                                        booking: this.booking._id,
                                    }
                                },
                                {
                                    onSuccess: async (options) => {
                                        await store.commit('payments/SET_ENTITY', {
                                            ...this.payment,
                                            ...{
                                                status: PAYMENT_STATUS.SUCCESS,
                                                options
                                            }
                                        });
                                        await store.dispatch('payments/save');
                                        await store.commit('payments/CLEAR_ENTITY');

                                        await store.dispatch('bookings/pay', { id: this.booking._id });
                                        
                                        await this.$router.push({ name: 'user-bookings' });
                                    },
                                    onFail: async (reason, options) => {
                                        await store.commit('payments/SET_ENTITY', {
                                            ...this.payment,
                                            ...{
                                                status: PAYMENT_STATUS.FAIL,
                                                reason,
                                                options
                                            }
                                        });
                                        await store.dispatch('payments/save');
                                        await store.commit('payments/CLEAR_ENTITY');
                                        console.log('pay onFail', options);
                                    }
                                }
                            );
                        }
                    } else {
                        this.personalDataPopup = true;
                    }
                } else {
                    this.$vuetify.goTo(this.$refs.bookingSidebarStop)
                }
            },
            number(event) {
                this.phoneWithCode = event.formattedNumber
            }
        }
    }
</script>

<style lang="scss">
.trip-booking {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    position: relative;
    @media all and (max-width: 1400px) {
        padding: 0 20px;
    }
    &__title {
        margin: 40px 0;
        font-weight: bold;
        font-size: 38px;
        line-height: 46px;
        color: #273155;
        @media all and (max-width: 768px) {
            font-size: 28px;
            line-height: 34px;
            margin: 40px 20px 20px;
        }
    }
    &__block-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #DA0A63;
        margin-bottom: 20px;
    }
    &__main-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 923px;
        margin-right: 32px;
        width: 67%;
        @media all and (max-width: 768px) {
            width: 100%;
            padding: 0;
            margin-right: 0;
        }
        &__dates {
            max-width: 923px;
            // height: 710px;
            background: #FFFFFF;
            box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 12px;
            padding: 32px 40px;
            @media all and (max-width: 768px) {
                width: 100%;
                padding: 24px 20px 30px;
            }
            &__info {
                display: flex;
                flex-direction: row;
                @media all and (max-width: 768px) {
                        flex-direction:column;
                    }
                &__date {
                    min-width: 290px;
                    min-height: 100%;
                    padding: 10px 60px 24px 0;
                    border-right: 1px solid #EBEBEB;
                    @media all and (max-width: 768px) {
                        border-right: none;
                        padding: 0;
                    }
                    &__value {
                        margin-bottom: 32px;
                    }
                    // &__selecter {

                    // }
                }
                &__rules {
                    padding: 0 0 10px 32px;
                    display: flex;
                    flex-direction: column;
                    gap: 40px;
                    @media all and (max-width: 768px) {
                        padding: 0;
                    }
                    &__item {
                        display: flex;
                        flex-direction: row;
                        &__picture {
                            min-width: 32px;
                            width: 32px;
                            height: 32px;
                            margin-right: 16px;
                            background-position: center;
                            background-size: contain;
                            &.users {
                                background-image: url(../../assets/Users.svg);
                            }
                            &.coin {
                                background-image: url(../../assets/Coin.svg);
                            }
                            &.shieldCheck {
                                background-image: url(../../assets/ShieldCheck.svg);
                            }

                        }
                        &__text {
                            padding-top: 5px;
                            font-size: 16px;
                            line-height: 19px;
                            letter-spacing: -0.02em;
                            color: #3A4256;
                            .rules__item__name {
                                font-weight: 600;
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
            &__bottom {
                @media all and (max-width: 768px) { 
                    display: none;
                }
                &__offers {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 32px;
                    width: 100%;
                    &__offer {
                        display: flex;
                        flex-direction: row;
                        background: #FFFFFF;
                        align-items: center;

                        &__picture {
                            min-width: 24px;
                            height: 24px;
                            background: url(../../assets/Discount.svg) no-repeat;
                            background-position: center;
                            background-size: cover;
                            margin-right: 20px;
                        }
                        &__text {
                            font-size: 12px;
                            line-height: 15px;
                            letter-spacing: -0.02em;
                            color: #A9AEB2;
                        }
                    }
                    &__secondOffer {
                        .trip-booking__main-container__dates__bottom__offers__offer__picture {
                            background: url(../../assets/Cards.svg) no-repeat;
                            background-position: center;
                            background-size: cover;
                        }
                    }
                }
            }
        }
        &__sidebar-container {
            max-width: 445px;
        }
        &__my-trip {
            // width: 30%;
            background: #FFFFFF;
            box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 12px;
            padding: 32px 20px 20px;
            // &.desktop {
            // }
            @media all and (max-width: 768px) { 
                width: 100%;
                margin-top: 30px;
            }
            &-fixed {
                width: 30%;
                max-width: 420px;
                position: fixed;
                top: 10px;
                right: calc(50% - 675px);
                @media all and (max-width: 1400px) {
                    width: 29%;
                    right: 25px;
                }
                @media all and (max-width: 1024px) {
                    width: 28.7%;
                    right: 20px;
                }
            }
            &-absolute {
                // width: 30%;
                position: absolute;
                bottom: 0;
                right: 0;
            }
            &__title {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.02em;
                color: #000000;
                margin-bottom: 20px;
            }
            &__rating {
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 15px;
            }
            &__direction {
                display: flex;
                // flex-direction: column;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 25px;
                // &__item{
                // }
            }
            &__dates {
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 32px;
            }
            &__info {
                // display: flex;
                // flex-direction: row;
                // justify-content: space-between;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.02em;
                color: #273155;
                padding: 12px 0;
                border-bottom: 1px solid #EBEBEB;
                &__value {
                    display: flex;
                    justify-content: space-between;
                    &.final-price {
                        color: #DA0A63;
                    }
                }
                &__comment {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;  
                    letter-spacing: -0.02em;
                    color: #9FA5AD;
                    @media all and (max-width: 768px) { margin-bottom: 4px; }
                }
                &.travelers {
                    border-top: 1px solid #EBEBEB;
                }
                &.final-price {
                    color: #DA0A63;
                }
                &.prepay {
                    font-weight: 500;
                    border: none;
                }
                &.final-pay {
                    font-weight: 500;
                    border: none;
                    margin-bottom: 8px;
                }
                &__personal-info {
                    margin-top: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    &__text {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 166%;
                        color: #A9AEB2;
                        text-align: left;
                        @media all and (max-width: 768px) {
                            font-weight: 300;
                            font-size: 12px;
                        }
                        &__accent {
                            font-weight: 500;
                            color: black;
                        }
                    }
                }
            }
        }
        &__details {
            margin-top: 75px;
            padding: 32px 40px;
            max-width: 923px;
            box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 12px;
            @media all and (max-width: 768px) {
                width: 100%;
                margin-top: 30px;
                padding: 24px 20px;
            }
            &__counter {
                display: flex;
                flex-direction: row;
                width: 100%;
                padding: 12px 0 16px;
                border-bottom: 1px solid #EBEBEB;
                margin-bottom: 32px;
            }
            &__traveler {
                padding-top: 32px;
                border-top: 1px solid #EBEBEB;
                &__title-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    @media all and(max-width: 768px) {
                        flex-direction: column;
                    }
                    &__switcher {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        @media all and(max-width: 768px) {
                            flex-direction: row;
                            justify-content: space-between;
                        }
                        &__title {
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 17px;
                            letter-spacing: -0.02em;
                            color: #9FA5AD;
                        }
                    }
                }
                &__title {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: -0.02em;
                    color: #000000;
                }
                &__title-comment {
                    margin-top: 4px;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: -0.02em;
                    color: #9FA5AD;

                }
                &__inputs {
                    margin: 20px 0;
                    // &.border {
                    //     border-bottom: 1px solid #EBEBEB;
                    //     padding-bottom: 32px;
                    // }
                }
                &__input {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 12px;
                    &__text {
                        width: 130px;
                        margin-right: 20px;
                        @media all and (max-width: 768px) { display: none;}
                    }
                    &__name {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        letter-spacing: -0.02em;
                        color: #273155;
                    }
                    &__comment {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        letter-spacing: -0.02em;
                        color: #9FA5AD;
                    }
                }
            }
        }
        &__trip-details {
            width: 445px;
            // min-height: 1000px;
            background: #ffffff;
            box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 12px;
            margin-top: 75px;
            padding: 32px 20px;
            &__key {
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.02em;
                color: #273155;
                margin-bottom: 12px;
            }
            &__value{
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.02em;
                color: #273155;
                margin-bottom: 20px;
            }
            &__conditions-title {
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.02em;
                color: #273155;
                margin-bottom: 12px;
            }
            &__condition{
                &__container {
                    display: flex;
                    flex-direction: row;
                }
                &__mark {
                    height: 20px;
                    width: 20px;
                    margin-right: 10px;
                    &.check {
                        background: url(../../assets/CheckMarkGreen.svg) 0 center no-repeat no-repeat;
                    }
                    &.x-mark{
                        background: url(../../assets/X-mark.svg) 0 center no-repeat no-repeat;
                    }
                }
                &__text-container {
                    font-weight: 500;
                    font-size: 16px;
                }
                &__title {
                    line-height: 150%;
                    letter-spacing: -0.02em;
                    &.green-text {
                        color: #00A699;
                    }
                    &.red-text {
                        color: #D80764;
                    }
                }
                &__description {
                    line-height: 22px;
                    letter-spacing: -0.02em;
                    color: #273155;
                    margin-bottom: 12px;
                }
            }
        }
    }
    &__personal-data {
        position: relative;
        max-width: 500px;
        padding: 30px 40px;
        background-color: #fff;
        font-family: 'Inter';
        font-style: normal;
        display: flex;
        flex-direction: column;
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
        &__title {
            font-weight: 600;
            font-size: 22px;
            line-height: 27px;
            color: #000000;
            margin: 0 auto 12px;
            text-align: center;
        }
        &__text {
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.02em;
            color: #A9AEB2;
            margin: 0 auto 25px;
            max-width: 500px;
            &__accent {
                font-weight: 500;
                color: black;
            }
        }
    }
    .v-input--radio-group--column .v-input--radio-group__input {
        flex-direction: row;
    }
    .v-input--selection-controls {
        margin-top: 0;
        padding-top: 0;
    }
    .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
        margin-bottom: 0;
    }
}
</style>